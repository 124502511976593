<template>
  <Loading v-if="initLoading" />
  <div class="mergeRecord" v-else>
    <Header>兑换记录</Header>
    <div class="container" ref="container">
      <div class="def" v-if="!list.length">
        <img src="../../../static/image/hezi2.png" alt="" />
        <p>暂无数据</p>
      </div>
      <div class="list">
        <div
          class="bg"
          v-for="item in list"
          :key="item.id"
          @click="goDetail(item)"
        >
          <div class="item">
            <div class="left">
              <img :src="item.goods ? item.goods.listPic : null" alt="" />
            </div>
            <div class="middle">
              <div class="title">{{ item.goods ? item.goods.name : '' }}</div>
              <div class="time">{{ item.created_at }}</div>
            </div>
            <div class="right">查看详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: {
        pageindex: 1,
        pagesize: 20,
        name: '',
        token: null,
      },
      list: [],
      noMore: false,
      initLoading: true,
    };
  },
  mounted() {
    this.initLoading = true;
    this.query.token = this.$route.query.token;
    this.getUserComposeLogList();
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener('scroll', this.moreCb);
    }
  },
  methods: {
    moreCb(e) {
      const target = e.target;
      if (target.clientHeight + target.scrollTop >= target.scrollHeight) {
        console.log('到底了');
        if (!this.noMore) {
          this.query.pageindex++;
          this.getUserComposeLogList('more');
        }
      }
    },
    async getUserComposeLogList(isMore) {
      try {
        const data = await this.$api.getPointComposeLogList(this.query);
        if (data.code === 0) {
          if (isMore) {
            this.list = this.list.concat(data.data || []);
          } else {
            this.list = data.data || [];
          }
          if ((data.data || []).length < this.query.pagesize) {
            this.noMore = true;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
        this.$nextTick(() => {
          if (this.$refs.container) {
            this.$refs.container.addEventListener('scroll', this.moreCb, true);
          }
        });
      }
    },
    goDetail(item) {
      this.$router.push(
        `/jiaoziChangeDetail?id=${item.id}&token=${this.query.token}`,
      );
    },
  },
};
</script>

<style scoped lang="less">
.mergeRecord {
  .container {
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    background: #f3f4f8;
    overflow: auto;
    .def {
      text-align: center;
      margin-top: 30px;
      p {
        font-size: 14px;
        color: #999;
      }
      img {
        width: 35%;
        margin-right: -25px;
      }
    }
    .list {
      padding: 20px;
      .bg {
        background: #fff;
        padding: 10px;
        border-radius: 5px;
        box-sizing: border-box;
        margin-bottom: 20px;
        .item {
          display: flex;
          align-items: center;
          .left {
            img {
              width: 80px;
              vertical-align: middle;
            }
          }
          .middle {
            padding-left: 10px;
            flex: 1;
            .title {
              font-size: 14px;
              font-weight: 600;
              color: #000000;
            }
            .time {
              font-size: 12px;
              margin-top: 10px;
              color: #a4a5a9;
            }
          }
          .right {
            font-size: 14px;
            color: #fab878;
          }
        }
      }
    }
  }
}
</style>
